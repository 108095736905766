.infoTooltipBlock {
  height: 18px;
  display: inline-flex;
  margin-left: 4px;
  cursor: pointer;
}

.printTechnicTooltip {
  margin-top: -60px;
  margin-left: 24px;

  img {
    margin: 0;
  }
}
