@import 'variables';

@mixin textEllipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin hoverBorder {
  border: 1px solid $primary100;
}
