@import 'editor/src/comonStyles/variables';

.checkbox {
  display: inline-flex;
  flex-direction: row;
  place-items: center center;
  cursor: pointer;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: $almostBlack;
  white-space: nowrap;
  text-wrap: wrap;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .check {
    border: 1px solid $neutral90;
    background: $white;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    color: $white;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-right: 7px;

    svg {
      margin: 0;
      stroke: $neutral90;

      > * {
        fill: $white;
        stroke: $white;
      }
    }

    &.on {
      background: $neutral90;
    }
  }

  &.disabled .check {
    cursor: not-allowed;
  }
}
