@import 'editor/src/comonStyles/variables';

.FixedBlock {
  border-top: 1px solid $black10;
}

.ScrollHelper {
  display: flex;
  position: relative;
  flex-direction: column;

  .content {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    flex-grow: 1;
  }
}

.vertical {
  align-items: flex-start;

  .content {
    align-items: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;  /* Safari and Chrome */
    }
  }
}

.horizontal {
  flex-direction: row;
  align-items: center;

  .content {
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;

    > * {
      flex-shrink: 0;

      span {
        padding: 0 4px;
      }
    }
  }
}

.borders {
  border-bottom: 1px solid $black10;
  border-top: 1px solid $black10;
}
