@import 'editor/src/comonStyles/variables';

.linkButton {
  color: #007CB4;

  font-family: "Gelato Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  margin-top: 8px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.switchButtonContainer {
  display: flex;
  align-items: center;
  color: #383838;

  font-family: "Gelato Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */

  .switchButton {
    margin-right: 8px;
  }
}
