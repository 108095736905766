@import 'editor/src/comonStyles/variables';

.CheckboxElement {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $almostBlack;
  border: 1px solid $neutral40;
  border-radius: 6px;
  margin-right: 6px;
  margin-bottom: 6px;
  padding: 10px;

  &.enabled:hover {
    background-color: $neutral10;
  }
}

.withPreview {
  width: 100%;
  align-items: flex-start !important;

  .labelWrapper {
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    display: flex;

    .labelBlock {
      flex-direction: column;
      display: flex;

      .description {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        color: $neutral60;
      }
    }
  }

  image {
    width: 60px;
    height: 60px;
    border-radius: 4px;
    border: 1px solid $neutral30;
  }
}

.unavailable {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #d0412d;
}
