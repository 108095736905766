@import 'editor/src/comonStyles/variables';

.controlTitleContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: start;
  padding-bottom: 6px;
}

.controlTitle {
  color: $neutral90;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  > div img {
    width: 18px;
    margin-left: 4px;
  }
}
