@import 'editor/src/comonStyles/variables';
@import 'editor/src/comonStyles/common';

.ImageListItem {
  position: absolute;
  justify-content: center;
  align-items: center;
  padding: 2px;
  cursor: pointer;

  :focus {
    outline: none !important;
  }

  &.hide {
    display: none;
  }

  
  &.selected {
    border: 2px solid $primary100;
  }

  .badges {
    position: absolute;
    bottom: 5px;
    left: 5px;
    right: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    pointer-events: none;
  }

  .removeButton {
    display: none;
  }

  .overlay {
    background-color: rgba(255, 255, 255, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: $black70;
    font-size: 11px;

    svg {
      fill: $almostBlack;
    }
  }

  .selectedIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: $white;
    position: absolute;
    top: 2px;
    border-radius: 50%;
    @include hoverBorder;

    svg {
      width: 12px;
      height: 12px;
    }

  }

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
  }

  &.mobile img {
    min-width: 60px;
    max-width: 150px;
    width: auto;
  }

  &.dragging {
    &::after {
      content: '';
      position: absolute;
      left: 4px;
      top: 4px;
      right: 4px;
      bottom: 4px;
      background-color: rgba(51, 51, 51, 0.05);
      border: 1px dashed rgba(51, 51, 51, 0.3);
      border-radius: 6px;
    }

    > * {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.ImageListItem:global(.draggableClone) {
  touch-action: none;
  width: 150px;
  pointer-events: none;
  height: auto;

  .badges {
    display: none !important;
  }

  .overlay {
    display: none;
  }

  .selectedIcon {
    display: none;
  }
}

.moreInfoContainer {
  position: absolute;
}

.nameTooltip {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: $maxMobileWidth) {
  .ImageListItem.multiselect {
    margin: 0 2px;
  }
}