@import 'variables';

// Flexbox grid styles
// Set your spacers values in _variables.scss

// .row-flex basic styles
@mixin flexrow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

// .row-flex > .col basic styles
@mixin flexcol {
  box-sizing: border-box;
}

.row-flex {
  @include flexrow;

  // Use class="col" if you want each item to have a space between columns
  // Otherwise use additional class="mx-0" for a .row-flex for set up margin to 0 and remove minus space over the row
  & > [class*="col"] {
    @include flexcol;

    // Columns modifiers
    &.col--center {  align-self: center;  }
    &.col--start  {  align-self: flex-start;  }
    &.col--end    {  align-self: flex-end;  }

    // Set a number of columns in 'variables' to use this
    // FE if you have classes 'col-1' for 2 items inside row - columns will be equal 50% of row width
    // You can use FE col-4, col-4, col-4 and you will have 3 columns equals for 33.3333% (like a bootstrap grid)
    // If you use just 'col' class each column will be looks like inline element and take his own width
    @for $i from 1 through $flex-columns {
      &.col-#{$i} {
        flex: $i;
        //flex: 0 0 calc((100% / #{$flex-columns}) * #{$i});
        //max-width: calc((100% / #{$flex-columns}) * #{$i});
      }
    }
  }

  // content justifying
  &--between  { justify-content: space-between; }
  &--around   { justify-content: space-around; }
  &--center   { justify-content: center; }
  &--end      { justify-content: flex-end; }

  // items alignment
  &--middle   { align-items: center; }
  &--bottom   { align-items: flex-end; }
  &--top      { align-items: flex-start; }
  &--stretch  { align-items: stretch; }
  &--baseline  { align-items: baseline; }

  // flex row modify
  &--nowrap   { flex-wrap: nowrap; }
  &--reverse  { flex-direction: row-reverse; }
  &--column {
    flex-direction: column;
    &.row-flex--reverse {
      flex-direction: column-reverse;
    }
  }

  // Heights
  &--fullheight {
    height: 100%;
  }
}
