@import 'editor/src/comonStyles/variables';
@import 'editor/src/comonStyles/common';

.ButtonElement {
  width: calc(50% - 4px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  color: $neutral90;
  border-radius: 8px;
  height: 40px;
  cursor: pointer;
  @include textEllipsis;
  border: 1px solid $neutral40;

  &:first-of-type {
    float: left;
    margin-right: 8px;
  }

  &:hover {
    background-color: $neutral10;
    border-color: $neutral60;
  }

  &.selected {
    background-color: $neutral90;
    color: $white;
    pointer-events: none;
    border: 1px solid $neutral90;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
    background-color: $black10;
    cursor: not-allowed;

    &.selected {
      background-color: $black70;
    }
  }
}
