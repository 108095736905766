@import 'editor/src/comonStyles/variables';
@import 'editor/src/comonStyles/common';

.button {
  margin-left: 10px;
  white-space: nowrap;
  padding: 0 2px;
  display: flex;
  flex-direction: column;
  height: auto;
  width: auto;

  .image {
    margin: 2px 0 5px;
    width: 100%;
    border-radius: 4px;
  }

  .imagePlaceholder {
    position: relative;
  }

  .label {
    font-size: 12px;
    width: 100%;
    text-align: center;
    @include textEllipsis;
    margin-bottom: 2px;
  }

  .premium {
    position: absolute;
    bottom: 9px;
    left: 2px;
  }
}
