@import 'editor/src/comonStyles/variables';

.ProductSizeControl {
  .label {
    margin: 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    color: $black;
    font-size: 12px;
  }
}
