@import 'editor/src/comonStyles/index';

.hideUsedCheckbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 50px;

  &.mobile {
    height: 100%;
  }

  .text {
    margin-left: -2px;
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: $almostBlack;
  }
}
