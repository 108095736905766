@import 'editor/src/comonStyles/index';

.technologyControl {
  padding-bottom: 12px;
}
.printTechnicControl {
  > div {
    width: 100%;
    margin-right: 0;
  }
}
.singlePrintTechnicControl {
  > div {
    > div {
      display: none;
    }

    &:before {
      content: '';
      display: block;
      height: 14px;
      width: 14px;
      border-color: $neutral90;
      border-width: 2px;
      border-radius: 100%;
      border-style: solid;
      margin-right: 8px;
    }
    &[class*="_active"]:after {
      content: '';
      position: absolute;
      left: 26px;
      height: 12px;
      width: 12px;
      border-radius: 100%;
      background-color: $neutral90;
    }
  }
}
