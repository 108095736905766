@import 'editor/src/comonStyles/variables';

.placeholderContainer {
  position: absolute;
  top: 0;
  left: 0;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform-origin: 0 0;
  background-color: rgba(210, 210, 210, 0.5);
  letter-spacing: 0;
  border-radius: 50%;
  pointer-events: auto;
  cursor: pointer;

  &:hover {
    outline: 1px solid #6aece9;
  }

  .icon {
    width: 50%;
    height: 20%;
    margin-bottom: 10%;
  }

  .title {
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 2%;
    margin-top: 0;
    text-align: center;
  }

  .subtitle {
    font-size: 17px;
    text-align: center;
  }
}
