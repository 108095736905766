@import 'editor/src/comonStyles/common';

.Accordion {
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;

  .header {
    padding: 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .subheader {
    @include textEllipsis;

    display: none;
  }

  &.collapsed .subheader {
    display: flex;
  }

  .buttonToggle {
    display: flex;
    cursor: pointer;
    transition: transform 0.2s ease-in;
  }

  &.collapsed .buttonToggle {
    transform: rotate(180deg);
  }
}

.content {
  overflow: hidden;
  transition: max-height 1s ease-in;
  max-height: 2000px;

  &.collapsed {
    max-height: 0;
    transition: max-height 0.2s ease-out;
  }
}
