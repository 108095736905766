@import 'editor/src/comonStyles/variables';

.h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: $almostBlack;
  display: flex;
  align-items: center;
  margin: 10px 0 0;

  &.noMargin {
    margin: 0;
  }

  &:not(.noRightMargin) div {
    margin-right: 16px !important;
  }
}
