@import 'editor/src/comonStyles/variables';

.designOptionsContainer {
  padding: 22px 0 8px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background-color: $white;

  .titleContainer {
    display: flex;
    flex-flow: row wrap;
  }

  .title {
    font-weight: 500;
    font-size: 14px;
    font-family: "Gelato Sans", sans-serif;
    letter-spacing: 0.32px;
    line-height: 18px;
    margin-right: 3px;
    color: $almostBlack;
  }

  .infoIcon {
    width: 18px;
    height: 18px;
    cursor: pointer;
    flex-direction: column;
  }
}

.tooltip {
  padding: 0;
}

.tooltipContent {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: $neutral90;
}

.fieldName {
  color: $neutral90;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  margin: 6px 0;
}

.inputField {
  padding: 12px;
  border-radius: 8px;
  border: 1px solid $rulerBorderColor;
  width: 100%;
  font-size: 14px;
  font-family: "Gelato Sans", sans-serif;
  letter-spacing: 0.32px;

  &:focus {
    border-color: $grey;
    outline: none;
  }

  &:hover {
    border-color: $grey;
  }
}

.designOption {
  padding: 8px;
  border-radius: 8px;
  border: 1px solid $neutral40;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-family: "Gelato Sans", sans-serif;
  letter-spacing: 0.32px;

  &:hover {
    background-color: $neutral10;

    .iconContainer {
      .binIcon {
        visibility: visible;
      }
    }

    input {
      background-color: $neutral10;
    }
  }

  .designOptionImage {
    width: 40px;
    height: 40px;
    object-fit: fill;
    border-radius: 2px;
    margin-right: 5px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 0 2px 2px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);
  }

  .designOptionInput {
    outline: none;
    border: 1px solid transparent;
    font-size: 14px;
    line-height: 20px;
    font-family: "Gelato Sans", sans-serif;
    letter-spacing: 0.32px;
  }

  .iconContainer {
    text-align: center;
    width: 20px;
    height: 20px;

    .binIcon {
      width: 20px;
      height: 20px;
      visibility: hidden;
      cursor: pointer;
    }
  }
}

.addNewDesignOption {
  padding: 12px;
  justify-content: center;
  width: 100%;
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  &:hover {
    background: transparent;
    border-color: $grey;
  }
}

.error {
  font-size: 11px;
  color: tomato;
}
