@import 'editor/src/comonStyles/variables';

.radio {
  display: inline-flex;
  place-items: center center;
  cursor: pointer;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: $almostBlack;

  &.withPreview {
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    background-color: $black10;
  }

  .check {
    margin-left: 6px;
    stroke: none;
  }

  .langePreview {
    width: 60px;
    height: 60px;
    border-radius: 4px;
    border: 1px solid $neutral30;
  }

  &.isSmallPreview {
    align-items: center;

    .labelWithPreview {
      align-items: center;
      display: flex;

      img {
        width: 30px;
        height: 30px;
        margin: 0 8px 0 0;
        object-fit: none;
      }
    }
  }

  .labelWrapper {
    flex-direction: column;
    display: flex;

    .description {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      color: $neutral60;
    }
  }
}

.radioButtonElement {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $almostBlack;
  border: 1px solid $neutral40;
  border-radius: 8px;
  margin: 0 8px 8px 0;
  padding: 10px;

  &.active {
    border: 2px solid $neutral90;
  }

  &.enabled:hover, &:not(.disabled):hover {
    background-color: $neutral10;
  }
}
