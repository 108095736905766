@import 'editor/src/comonStyles/variables';

.headerContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.TabContentHeader {
  padding: 10px 11px 13px;
  margin: 0 -11px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header {
    font-size: 22px;
  }

  .headerWrapper {
    display: flex;
    align-items: center;
    gap: 18px;
  }

  > div {
    margin-right: 8px;
  }

  .close {
    margin-right: 0;
    flex: 1 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
