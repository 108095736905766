@import 'editor/src/comonStyles/variables';

.container {
    padding: 4px 12px;
    margin: 8px 0;
    border: 1px solid $neutral70;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .more button {
        display: flex;
        align-items: center;
    }
}