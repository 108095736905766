@import 'editor/src/comonStyles/common';

.productTitle {
  flex: 1;
  @include textEllipsis;
  font-size: 21px;
}

.iconInfoContainer {
  display: flex;
  margin-left: 4px;
  height: 18px;
  cursor: pointer;
}

.close {
  margin-right: 0;
  flex: 1 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
