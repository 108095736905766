@import 'editor/src/comonStyles/variables';

.tabsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.tabItem {
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Gelato Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: $primary100;
  cursor: pointer;
  flex: 1;
}

.active {
  font-weight: 700;
  box-shadow: 0 4px 0 $primary100;
}