.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
}

body .welcomePopup {
  width: 420px;

  p {
    color: #6B6B6B;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 0.24px;
  }
}

.dummyTopElement {
  position: fixed;
  top: 0;
  right: 0;
  width: 150px;
}
