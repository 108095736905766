@import 'editor/src/comonStyles/variables';
@import 'editor/src/comonStyles/common';

.wrapper {
  position: relative;
}

.selectLabel {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.32px;
  margin-bottom: 4px;
  cursor: pointer;
  width: 300px;
}

.selectedOption {
  display: flex;
  font-size: 14px;
  align-items: center;
  padding: 12px;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid $neutral30;
  cursor: pointer;
  margin-bottom: 12px;

  &.isOpen {
    border: 2px solid $neutral90;
  }

  .selectedOptionLabel {
    @include textEllipsis;

    flex-grow: 1;
    color: $neutral90;
  }

  .placeholder {
    color: $neutral70;
  }

  .chevronIcon {
    display: grid;
    margin-left: auto;
    transform: rotate(180deg);
    transition: all 0.3s ease-in-out;

    &.isOpen {
      transform: rotate(0deg);
    }
  }
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  background: $white;
  border: 1px solid #ccc;
  font-size: 14px;
  // stylelint-disable-next-line number-no-trailing-zeros
  box-shadow: 0 2px 6px 2px rgba(33, 33, 33, 0.15), 0 1px 2px 0 rgba(33, 33, 33, 0.30);
  z-index: 1001;
  border-radius: 8px;
  padding: 12px;
  display: none;
  gap: 8px;
  overflow: hidden;
  transition: all 1s ease-in-out;
  height: 0;

  &.isOpen {
    display: grid;
    height: auto;
    max-height: 300px;
    overflow-y: scroll;
  }

  .option {
    cursor: pointer;
    padding: 13px;
    border-radius: 8px;
    align-items: start;
    display: flex;
    flex-direction: row;

    &.withPreview {
      border: 1px solid $neutral30;
      padding: 8px;
      align-items: center;
      flex-direction: row;

      .label.multiSelect {
        justify-content: space-between;
      }

      &.selected {
        border: 1px solid $neutral90;
        font-weight: 400;
      }

      &.multi {
        border: none !important;
      }
    }

    &.selected {
      font-weight: 700;
    }

    .preview {
      width: 60px;
      height: 60px;
      border-radius: 4px;
      border: 1px solid $neutral30;
    }

    .label {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &.multiSelect {
        justify-content: start;
        gap: 10px;

        .check {
          stroke: none;
        }
      }
    }

    &.disabled {
      cursor: not-allowed;

      .label {
        color: $neutral50;
      }
    }

    .content {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      color: $neutral60;
    }

    &:hover {
      background: $neutral10;
    }
  }
}
