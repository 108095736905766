@import 'editor/src/comonStyles/variables';

.wrapper {
  position: fixed;
  left: 0;
  top: 0;
  font-family: "Gelato Sans";

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 57px;
    border-top: 1px solid rgba(33, 33, 33, 0.05);
    padding: 0 8px;

    .button {
      border: none;
      height: 32px;
      border-radius: 40px;
    }
  }
}

.contentWrapper {
  width: 285px;
  background-color: white;
  border-radius: 8px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  padding: 0 24px;
}

.headerTitle {
  color: #000000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.headerCloseIcon {
  color: #000000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
  fill: $neutral50;
  stroke: $neutral50;
}

.content {
  color: $primary100;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 8px 24px 16px 24px;
}

.counter {
  color: $neutral60;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.pointer {
  position: absolute;
  width: 20px;
  height: 20px;
  transform: rotate(-45deg);
  background-color: white;
}