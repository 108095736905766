@import 'editor/src/comonStyles/variables';

.tabContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.headerBlock {
  border-bottom: 1px solid $lightGrey;
}
