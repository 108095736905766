@import 'editor/src/comonStyles/variables';

.Badge {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 8px;

  .infoIcon {
    height: 16px;
    width: 16px;
  }

  &:hover {
    opacity: 0.8;
  }

  &.noButton:hover {
    opacity: 1;
  }
}

.infoBadge {
  display: flex;
  width: auto;
  height: 20px;
  padding: 3px 8px;
  background-color: $neutral20;
  font-size: 12px;
  gap: 3px;

  svg {
    min-height: 18px;
  }
}
