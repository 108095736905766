@import 'editor/src/comonStyles/variables';

.helpCenterContainer {
  padding-top: 12px;
  font-family: 'Gelato Sans', sans-serif;

  > div {
    margin: 6px 0 16px;
  }
}

.articleBlock {
  display: flex;
  margin: 8px 0;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid $neutral20;
  justify-content: space-between;
  align-items: center;
  color: $neutral90;
  font-size: 12px;
  text-decoration: none;
}