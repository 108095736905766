@use 'sass:color';
@import 'editor/src/comonStyles/variables';

$default-button-size: 40px;

.button {
  background: $white;
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #212121;
  height: $default-button-size;
  padding: 0 20px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(.disabled):hover {
    background: color.mix($white, $black, 95%);
  }

  &:not(.disabled):active {
    border-color: #212121;
    color: $white;
  }

  &.primary {
    background: #212121;
    border-color: #212121;
    color: $white;
    border-style: none;

    &:not(.disabled):hover {
      background: #212121;
      border-color: #212121;
      box-shadow: 0 4px 6px rgba($black, 0.06), 0 4px 12px rgba(#212121, 0.32);
    }

    &:not(.disabled):active {
      background: color.mix(#212121, $black, 70%);
      border-color: color.mix(#212121, $black, 70%);
    }

    svg {
      > * {
        stroke: $white;
        fill: $white;
      }
    }
  }

  &.secondary {
    color: #212121;
    border: 1px solid #212121;
    font-style: normal;
    font-weight: normal;

    &:not(.disabled):hover {
      background: $neutral10;
      color: #212121;
    }

    &:not(.disabled):active {
      background: $neutral10;
      color: #212121;
    }

    &.on {
      background: $neutral10;
      color: #212121;
    }

    svg {
      > * {
        stroke: #212121;
        fill: #212121;
      }
    }
  }

  &.tertiary {
    padding: 0 10px;
    color: $almostBlack;
    border: 1px solid;
    border-color: transparent;
    font-style: normal;
    font-weight: normal;

    &:not(.disabled):hover {
      background: $black10;
      color: $almostBlack;
    }

    &:not(.disabled):active {
      background: $black10;
      color: $almostBlack;
      border-color: $black70;
    }

    &.on {
      background: transparent;
      color: $almostBlack;
    }

    svg {
      > * {
        stroke: $almostBlack;
        fill: $almostBlack;
      }
    }
  }

  &.transparent {
    padding: 0 10px;
    color: #212121;
    border: 1px solid;
    border-color: transparent;
    font-style: normal;
    font-weight: normal;

    &:not(.disabled):hover {
      background: $neutral10;
    }

    &:not(.disabled):active {
      background: $blue10black10;
      border-color: #212121;
      color: #212121;
    }

    &.on {
      background: transparent;
    }

    svg {
      > * {
        stroke: $almostBlack;
        fill: $almostBlack;
      }
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.noPadding {
    padding: 0;
  }

  &.rounded {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    padding: 0;
    width: $default-button-size;
  }

  &.danger {
    color: $white;
    background-color: $destructive70;

    svg {
      > * {
        stroke: $white;
        fill: $white;
      }
    }

    &:not(.disabled):hover {
      background: $destructive90;
    }

    &:not(.disabled):active {
      background: $destructive90;
    }
  }

  &.showLoader svg > * {
    fill: none;
  }

  &.fullWidth {
    width: 100%;
  }

  &.small {
    height: 25px;
    font-size: 12px;
    font-weight: 500;
    color: $black;
    padding: 4px 12px;
    border-radius: 24px;

    &:hover {
      background-color: $neutral20;
    }
  }
}
