@import 'editor/src/comonStyles/index';

.dropDown {
  top: 0;
  margin-left: 10px;
  width: 240px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.imageInfo {
  color: $primary100;
  font-family: Gelato Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  padding: 16px;
  pointer-events: all;
  border-bottom: 1px solid #E6E6E6;
  display: flex;
  align-items: center;

  .imageInfoText {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    margin-left: 4px;
    line-height: 30px;
  }

  .editInput {
    color: $primary100;
    font-family: Gelato Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
  }
}


.info {
  // Line clamp for max 2 lines
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  position: relative;
  cursor: pointer;
}

.updateButton {
  margin-left: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;

  &:hover {
    background-color: $neutral10;
  }

  svg {
    width: 28px;
    height: 28px;
    padding: 3px;
  }
}
