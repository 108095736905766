@import 'editor/src/comonStyles/variables';

.tabsBlock {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-shrink: 0;
  padding-top: 12px;
}

.tabItem {
  font-family: 'Gelato Sans', sans-serif;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding: 10px;
  font-size: 16px;
  line-height: 20px;
  color: $black90;
  border-bottom: 4px solid transparent;
  cursor: pointer;
  text-align: center;
  position: relative;

  &.active {
    cursor: default;
    color: $primary100;
    border-bottom-color: $primary100;
  }
}
