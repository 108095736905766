@import 'editor/src/comonStyles/variables';

.wrapper {
  display: flex;
  color: #5E4200;

  font-family: Gelato Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  align-items: center;
}

.buttonIndicator {
  background-color: $signalOrange;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  white-space: nowrap;
  border-radius: 10px;
  min-width: 20px;
  height: 20px;
  margin: 0 8px;
}

.actionButton {
  color: #5E4200;
  margin-left: 8px;
  text-decoration: underline;
}

.icon {
  fill: white;
}

.cursorPointer {
  cursor: pointer;
}

