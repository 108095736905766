@import 'editor/src/comonStyles/variables';
@import 'editor/src/comonStyles/common';

.wrapper {
  width: 89px;
  position: relative;
  margin-bottom: 8px;

  .tooltipWrapper {
    width: 100%;

    &:hover {
      .preview {
        @include hoverBorder;
      }

      .removeButton {
        display: flex;
      }
    }
  }

  .preview {
    cursor: pointer;
    border: 1px solid $rulerBorderColor;
    border-radius: 8px;
    height: 89px;
    width: 100%;
    margin-bottom: 4px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .removeButton {
    position: absolute;
    right: 4px;
    top: 4px;
    width: 28px;
    height: 28px;
    border: 1px solid $neutral40;
    border-radius: 24px;
    background-color: $white;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    path {
      fill: $neutral70;
      stroke: $neutral70;
    }

    display: none;
    opacity: 0.6;

    &:hover {
      @include hoverBorder;
      opacity: 1;
    }
  }

  .removeIcon {
    width: 17px;
    height: 17px;
    display: flex;
    align-items: center;
  }

  .title {
    display: block;
    font-family: "Gelato Sans", sans-serif;
    font-size: 12px;
    letter-spacing: 0.32px;
    font-weight: 400;
    line-height: 16px;
    z-index: 100500;
    border-radius: 2px;
    padding: 2px 0;
    @include textEllipsis;
    transition:
            padding 0.3s ease,
            display 0.3s ease,
            opacity 0.3s ease;

    &:hover {
      outline: 1px solid $neutral50;
      margin: 0 1px;
      padding: 2px 3px;
      cursor: text;
    }

    &.hide {
      opacity: 0;
      display: none;
    }
  }

  .ediTitle {
    width: 100%;
    font-family: "Gelato Sans", sans-serif;
    font-size: 12px;
    letter-spacing: 0.32px;
    margin: -1px 0;
    opacity: 0;
    display: none;
    height: 21.5px;
    transition:
            display 0.3s ease,
            opacity 0.3s ease;

    &.show {
      opacity: 1;
      display: block;
    }

    &:focus-visible {
      outline-color: $primary100;
    }
  }
}
