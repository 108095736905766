@import 'editor/src/comonStyles/common';

.ButtonFontFamily {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 180px;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;

  span {
    @include textEllipsis;
  }
}
