@import 'editor/src/comonStyles/variables';

.helpCenterContainer {
  padding-top: 12px;
  font-family: 'Gelato Sans', sans-serif;
}

.tutorialBlock {
  padding: 4px 0;
  position: relative;
  width: 100%;
  overflow: hidden;

  .tutorialTitle {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.24px;
    padding-bottom: 6px;
  }
}

.tutorialFrame {
  width: 100%;
  background-color: $neutral20;
  border-radius: 8px;
  height: 0;
  padding-bottom: 56.25%; // video ratio 9/16

  iframe {
    width: 100%;
    aspect-ratio: 1.78;
    border: 0;
    border-radius: 8px;
  }
}