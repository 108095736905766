@import 'editor/src/comonStyles/variables';

.RadioElement {
  font-weight: 400;
  line-height: 20px;
  color: $almostBlack;
  border: 1px solid $black30;
  border-radius: 8px;
  margin-right: 12px;
  margin-bottom: 12px;
  padding: 10px;

  &.active {
    border: 2px solid $neutral90;
  }

  &.enabled:hover {
    background-color: $neutral10;
  }
}
