@import 'editor/src/comonStyles/index';

.item {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  height: 100%;

  &.disabled {
    cursor: unset;

    &:hover, &.active {
      .image {
        border-color: $black30;
        outline: none;
      }
    }
  }

  .image {
    background: $black5;
    background-image: linear-gradient(to right, transparent 33%, rgba(255, 255, 255, 0.8) 50%, transparent 66%);
    animation: shine 1s infinite;
    background-size: 300% 100%;
    border: 1px solid $black30;
    height: 100%;
    width: 100px;

    &.loaded {
      width: auto;
      background-image: none;
    }

    img {
      height: 100%;
    }
  }

  &.loaded .image {
    width: auto;
  }

  &:hover, &.active {
    .image {
      border-color: #212121;
      outline: 1px solid #212121;
    }
  }
}

@keyframes shine {
  0% {
    background-position: right;
  }

  100% {
    background-position: left;
  }
}
