@import 'editor/src/comonStyles/variables';
@import 'editor/src/comonStyles/common';

.AbstractWarningItem {
  display: flex;
  flex-direction: column;
  position: relative;

  .item {
    display: grid;
    grid-template:
      'icon title warning chevron' auto
      'icon name warning chevron' auto
      / auto 1fr auto auto;
    justify-content: center;
    align-items: center;
    padding: 6px;
    margin: 6px 0;
  }

  .icon {
    grid-area: icon;
    margin-right: 6px;
    width: 24px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .title {
    grid-area: title;
    justify-items: start;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .name {
    grid-area: name;
    justify-items: start;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    @include textEllipsis;
  }

  .warning {
    grid-area: warning;
    margin-left: 6px;
  }

  .chevron {
    grid-area: chevron;
    margin-left: 12px;
    display: flex;
  }

  .content {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding: 0 6px 6px;
    margin-bottom: 6px;
  }
}

.AbstractWarningItem + .AbstractWarningItem {
  border-top: 1px solid $black5;
}
