@import 'editor/src/comonStyles/variables';

.wrapper {
  display: inline-block;

  .previewImage {
    height: 24px;
    width: 24px;
    border-radius: 4px;
    border: 1px solid $neutral40;
    margin-right: 9px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 24px;
      width: 24px;
      object-fit: fill;
    }

    &.selected {
      border: 2px solid $neutral90;
    }

    &.disabled {
      cursor: not-allowed;
    }

    .check {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      svg path {
        stroke-width: 1;
        stroke: rgba($black50, 50);
      }
    }
  }
}

.disabled {
  pointer-events: none;
}
